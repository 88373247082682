



























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ContactMail } from "../model/contact-mail";
import TextContactNL from "@/locales/text-contact.nl.md";
import TextContactEN from "@/locales/text-contact.en.md";
import Axios from "axios";

let marked = require("marked");

@Component
export default class ContactComponent extends Vue {
  mail: ContactMail = {};
  mailSent: boolean = false;
  mailError: boolean = false;

  constructor() {
    super();
  }

  public sendMail(evt: any) {
    var url: string = process.env.VUE_APP_CONTACT_FORM_URL || "";
    Axios.post(url, this.mail)
      .then(() => {
        this.mailSent = true;
      })
      .catch(() => {
        this.mailError = true;
      });
  }

  public getContactText(): any {
    const md = this.$i18n.locale === "nl" ? TextContactNL : TextContactEN;
    marked.setOptions({ breaks: true });
    return marked(md);
  }
}
